/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
          $('.carroussel-slick').slick({
              infinite: true,
              speed: 500,
              fade: true,
              cssEase: 'linear'
          });
          $('[data-fancybox="gallery"]').fancybox({
              animationEffect : false,
              buttons: [
                  "close"
              ],
          });
          // menu principal
          $('.bouton-menutoggle').click(function(event) {
              event.preventDefault();
              $(this).toggleClass('menu-closer');
              $(this).parents('.menu-main').toggleClass('opened');
              if (screen.width < 1201) {
                  $('body').toggleClass('overlayed');
              };
          });

          $('body').click(function(e) {
              if ($(e.target).closest('.menu-deep-0').length === 0) {
                  $('.menu-deep-1').removeClass('open');
              }
          });

          //click niveau 0
          $(".menu-deep-0 ul.menu-pr > li").click(function(event){
              if (event.target === this){
                  $('.menu-deep-1').removeClass('open');
                  event.preventDefault();
                  $(this).find('.menu-deep-1').toggleClass('open');
              }
          });

          //bouton de navigation deep 0 à 3 du menu
          $(".menu-deep-toggle").click(function(event){
              event.preventDefault();
              $(this).parent('a').next('div').toggleClass('open');
              $('.open').parents('a').addClass('open');
              $(this).parent('a').toggleClass('open-menu');
          });
          // filtre Agenda
          $('.select-agenda').select2({
              width: "resolve"
          });
          // filtre Publications
          $('.select-publication').select2({
              width: "resolve"
          });
          //filtre actualite
          $('.select-actualite').select2({
              width: "resolve"
          });
          //filtre Annuaire
          $('.select-annuaire').select2({
              width: "resolve"
          });
          // Date Pickers
          $("#datepicker-from").datepicker();
          $("#datepicker-to").datepicker();
          $.datepicker.regional['fr'] = {
              clearText: 'Effacer',
              clearStatus: '',
              closeText: 'Fermer',
              closeStatus: 'Fermer sans modifier',
              prevText: '<Préc',
              prevStatus: 'Voir le mois précédent',
              nextText: 'Suiv>',
              nextStatus: 'Voir le mois suivant',
              currentText: 'Courant',
              currentStatus: 'Voir le mois courant',
              monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
                  'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
              ],
              monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun',
                  'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'
              ],
              monthStatus: 'Voir un autre mois',
              yearStatus: 'Voir un autre année',
              weekHeader: 'Sm',
              weekStatus: '',
              dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
              dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
              dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
              dayStatus: 'Utiliser DD comme premier jour de la semaine',
              dateStatus: 'Choisir le DD, MM d',
              dateFormat: 'dd/mm/yy',
              firstDay: 0,
              initStatus: 'Choisir la date',
              isRTL: false
          };
          $.datepicker.setDefaults($.datepicker.regional['fr']);

      },
      finalize: function() {

      }
    },
    // Home page
    'home': {
      init: function() {
          $('.slider').slick({
              autoplay: true,
              arrows: true,
              dots: true,
              fade: true,
              prevArrow: "<i class='icofont-simple-left slick-control slick-control-left'></i></i>",
              nextArrow: "<i class='icofont-simple-right slick-control slick-control-right'></i>"
          });

          function scrollAndActif(element) {
              if(! $(element).closest('div').hasClass('actif')){
                  $('html, body').animate({
                      scrollTop: $(element).offset().top -200
                  }, 1000);
                  $(".highlighted-item").closest('div').removeClass('actif');
                  $(element).closest('div').addClass('actif');
              }else{
                  $(".highlighted-item").closest('div').removeClass('actif');
              }
          }

          // menu par profil (je suis: habitant/entreprise/touriste)
          if (screen.width > 1200) {

              $(window).load(function() {
                  maxMenuHeight = 0;
                  $('.nav-second-submenu').each(function(){
                      $(this).css({

                          'bottom' : 0 - $(this).outerHeight()
                      })
                      maxMenuHeight = (maxMenuHeight < $(this).outerHeight()) ? $(this).outerHeight() : maxMenuHeight;
                  });

                  $( document ).scroll(function() {
                      if($(window).scrollTop() > maxMenuHeight + 400){
                          $('.nav-second-submenu').removeClass('second-menu-opened');
                          $('body').removeClass('overlayed');
                      }
                  });

              });

              $('.submenu-toggle-communaute').click(function(event) {

                  event.preventDefault();

                  scrollAndActif($(this));

                  $(".second-submenu-communaute").css({
                      'bottom' : 0 - $(".second-submenu-communaute").outerHeight()
                  })

                  $(".second-submenu-communaute").toggleClass('second-menu-opened');
                  $(".second-submenu-habitant").removeClass('second-menu-opened');
                  $(".second-submenu-entreprise").removeClass('second-menu-opened');
                  $(".second-submenu-touriste").removeClass('second-menu-opened');
                  $('body').removeClass('overlayed');
                  if ($('.second-menu-opened')[0]) {
                      $('body').addClass('overlayed');
                  };
              });
              $('.submenu-toggle-habitant').click(function(event) {

                  event.preventDefault();

                  scrollAndActif($(this));

                  $(".second-submenu-habitant").css({
                      'bottom' : 0 - $(".second-submenu-habitant").outerHeight()
                  })

                  $(".second-submenu-communaute").removeClass('second-menu-opened');
                  $(".second-submenu-habitant").toggleClass('second-menu-opened');
                  $(".second-submenu-entreprise").removeClass('second-menu-opened');
                  $(".second-submenu-touriste").removeClass('second-menu-opened');
                  $('body').removeClass('overlayed');
                  if ($('.second-menu-opened')[0]) {
                      $('body').addClass('overlayed');
                  };
              });
              // $('.submenu-toggle-entreprise').click(function(event) {
              //
              //     event.preventDefault();
              //
              //     scrollAndActif($(this));
              //
              //     $(".second-submenu-entreprise").css({
              //         'bottom' : 0 - $(".second-submenu-entreprise").outerHeight()
              //     })
              //
              //     $(".second-submenu-communaute").removeClass('second-menu-opened');
              //     $(".second-submenu-habitant").removeClass('second-menu-opened');
              //     $(".second-submenu-entreprise").toggleClass('second-menu-opened');
              //     $(".second-submenu-touriste").removeClass('second-menu-opened');
              //     $('body').removeClass('overlayed');
              //     if ($('.second-menu-opened')[0]) {
              //         $('body').addClass('overlayed');
              //     };
              // });
              $('.submenu-toggle-touriste').click(function(event) {

                  event.preventDefault();

                  scrollAndActif($(this));

                  $(".second-submenu-touriste").css({
                      'bottom' : 0 - $(".second-submenu-touriste").outerHeight()
                  })

                  $(".second-submenu-communaute").removeClass('second-menu-opened');
                  $(".second-submenu-habitant").removeClass('second-menu-opened');
                  $(".second-submenu-entreprise").removeClass('second-menu-opened');
                  $(".second-submenu-touriste").toggleClass('second-menu-opened');
                  $('body').removeClass('overlayed');
                  if ($('.second-menu-opened')[0]) {
                      $('body').addClass('overlayed');
                  };
              });
              $('.second-menu-close').click(function(event) {
                  event.preventDefault();
                  // Ajout Florian
                  $(".highlighted-item").closest('div').removeClass('actif');
                  //
                  $('.nav-second-submenu').removeClass('second-menu-opened');
                  $('body').removeClass('overlayed');
              });
              $(document).on('keyup', function(evt) {
                  if (evt.keyCode == 27) {
                      $('.nav-second-submenu').removeClass('second-menu-opened');
                      $('body').removeClass('overlayed');
                  };
              });
          };
      },
      finalize: function() {
      }
    },
    // About us page, note the change from about-us to about_us.
    'annuaire': {
      init: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery);
